import { TStripNull } from '@/types/generics'
import { paths, components } from 'sdk/openapitypescript/mdip-api'
export type Path = keyof paths
export type PathMethod<T extends Path> = Extract<keyof paths[T], string>

export type RequestParams<
  P extends Path,
  M extends PathMethod<P>,
> = paths[P][M] extends {
  parameters: any
}
  ? paths[P][M]['parameters']
  : undefined
export type ResponseType<
  P extends Path,
  M extends PathMethod<P>,
> = paths[P][M] extends {
  responses: { 200: { content: { 'application/json': any } } }
}
  ? paths[P][M]['responses'][200]['content']['application/json']
  : undefined

export const resolveUrlParams = (params: any, url: string) => {
  if (params) {
    params.map(
      (item: {
        path?: { [key: string]: any }
        query?: { [key: string]: any }
      }) => {
        for (const key in item.path) {
          url = url.replace(`{${key}}`, item.path[key])
        }
        if (item.query) {
          url = `${url}?${new URLSearchParams(item.query).toString()}`
        }
      },
    )
  }
  return url
}

export type Dataset = components['schemas']['Dataset']
export type DatasetSlim = components['schemas']['DatasetList']
export type User = components['schemas']['User']
export type ApiKeyItem = components['schemas']['APIKey']
export type APIKeyCreateSerializerResponse =
  components['schemas']['APIKeyCreateSerializerResponse']
export type DatasetSearchFilter = components['schemas']['DatasetSearchFilter']
export type ContactUsResponse = components['schemas']['ContactRequest']
export type FavoriteDataset = components['schemas']['FavoriteDataset']
export type NewsletterSubscribeResponse =
  components['schemas']['NewsletterSubscription']
export type DeleteAccountResponse =
  components['schemas']['AccountDeletionRequest']
export type TrainingHistoryItem = components['schemas']['PatchedTrainingRun']
export type TrainingRunItem = components['schemas']['TrainingRun']
export type TrainingRunsCreateResponse =
  components['schemas']['TrainingRunCreate']
export type PopularTag = components['schemas']['PopularTag']
export type OrganizationMembers = components['schemas']['OrganizationUser']
export type Organization = components['schemas']['Organization']
export type OrganizationStats = components['schemas']['organizationStats']
export type AccountNotificationsSettingsData =
  components['schemas']['accountNotificationsSettings']
export type MemberInvitationResponse =
  components['schemas']['OrganizationInvitationCreate']
export type OrganizationInvitation =
  components['schemas']['OrganizationInvitation']
export type PatchedOrganization = components['schemas']['PatchedOrganization']
export type OrganizationExpectedMembersEnum =
  components['schemas']['ExpectedMembersEnum']
export type UserOnboardingQuestion = components['schemas']['Question']
export type UserOnboardingQuestionOption =
  components['schemas']['QuestionOption']
export type UserOnboardingQuestionAnswer = components['schemas']['Answer']
export type Recipe = components['schemas']['RecipeList']
export type RecipeCreate = components['schemas']['RecipeCreate']
export type AnnotatedObjectReport =
  components['schemas']['AnnotatedObjectReport']
export type ExperimentEnvironment =
  components['schemas']['ExperimentEnvironment']
export type Experiment = components['schemas']['Experiment']
export type OrganizationUser = components['schemas']['UserSerializerMinimal']
export type AnnotationReport = components['schemas']['AnnotationReport']
export type AnnotatedObject = components['schemas']['AnnotatedObject']
export type AnnotatedObjectEntityTypeEnum =
  components['schemas']['EntityTypeEnum']
export type AnnotatedObjectEntityType = `${AnnotatedObjectEntityTypeEnum}`
export type DatasetVariant = components['schemas']['DatasetVariant']
export type DistributionValue = components['schemas']['DistributionValue']
export type DistributionCategory = components['schemas']['DistributionCategory']
export type DistributionType = components['schemas']['DistributionType']
export type DistributionTypeSectionEnum = components['schemas']['SectionEnum']
export type DistributionTypeSection = `${DistributionTypeSectionEnum}`
export type Resolution = components['schemas']['Resolution']
export type DatasetPlanEnum = components['schemas']['PlanEnum']
export type SplitAnnotationsReport =
  components['schemas']['SplitAnnotationsReport']
export type SplitAnnotationsReportObjectReport = TStripNull<
  SplitAnnotationsReport['annotated_object_reports']
>[0]
