import { SUPPORT } from '@/constants'

export const navLinks = [
  {
    label: 'Data Library',
    href: '/data-library',
    testId: 'navbar-datalibrary',
  },
  {
    label: 'Features',
    href: '/features',
    testId: 'navbar-features',
  },
  {
    label: 'Ethics',
    href: '/ethics',
    testId: 'navbar-ethics',
  },
  {
    label: 'Documentation',
    href: SUPPORT.docs,
    testId: 'navbar-documentation',
    external: true,
  },
]

export const internalNav = {
  auth: {
    signin: '/auth/signin',
  },
  aboutUs: '/about-us',
  contactUs: '/contact-us',
  privacyPolicy: '/privacy-policy',
  termsAndConditions: '/terms-and-conditions',
  cookiePolicy: '/cookie-policy',
  ethics: '/ethics',
  features: '/features',
  dataLibrary: '/data-library',
  popularDatasets: '/popular-datasets',
}
