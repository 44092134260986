import YourCollectionIcon from '@public/assets/icons/new/Icon Your Collections.svg'
import AccessTokensIcon from '@public/assets/icons/new/access.svg'

import IconDashboard from '@public/assets/icons/new/Icon Dashboard.svg'
import IconProject from '@public/assets/icons/new/Icon Project.svg'
import IconDataset from '@public/assets/icons/new/Icon Dataset.svg'
import IconDocumentation from '@public/assets/icons/new/Icon Documentation.svg'
import IconHelp from '@public/assets/icons/new/Icon help.svg'
import IconWebsite from '@public/assets/icons/new/Icon Website.svg'
import IconSlack from '@public/assets/icons/new/teenyicons/slack-solid.svg'
import AccountSettingsIcon from '@public/assets/icons/new/account_settings.svg'

import IconOrganization from '@public/assets/icons/new/Icon Organization.svg'
import IconPledge from '@public/assets/icons/new/ri/honour-fill.svg'

import { GetServerFeatureToggle } from '@/components/FeatureFlags'
import { KeyOf } from '@/types/generics'

const [isEnabled] = GetServerFeatureToggle()

export const SUPPORT = {
  email: 'support-mdi@milestonesys.com',
  apiKey: 'https://mdi.readme.io/docs/create-an-api-key',
  upvoteFeatures: 'https://mdi.readme.io/discuss/6704bdd3d03e9e0032f619a8',
  scriptSamples: 'https://mdi.readme.io/docs/train-using-script2model',
  help: 'https://mdi.readme.io/docs/getting-started-with-mdi',
  docs: 'https://mdi.readme.io/docs',
  slack:
    'https://join.slack.com/t/milestonedatainsights/shared_invite/zt-2xj23s42v-NzdlFg5NzkIkUKAxSbwxyA',
}

export const DATASET_PAGE_SIZE = 10

export const DASHBOARD_LINKS = [
  {
    newTab: false,
    title: 'Dashboard',
    href: '/dashboard',
    iconSrc: IconDashboard,
    isEnabled: true,
  },
  {
    newTab: false,
    title: 'Experiments',
    href: '/dashboard/experiments',
    iconSrc: IconProject,
    isEnabled: true,
  },
  {
    newTab: false,
    title: 'My Collections',
    href: '/dashboard/your-collection',
    iconSrc: YourCollectionIcon,
    isEnabled: true,
  },
  {
    newTab: false,
    title: 'Datasets',
    href: '/dashboard/datalibrary-search',
    iconSrc: IconDataset,
    isEnabled: true,
  },
  {
    newTab: false,
    title: 'API Keys',
    href: '/dashboard/api-keys',
    iconSrc: AccessTokensIcon,
    isEnabled: true,
  },
]

export const AVATAR_NAVBAR_LINKS = [
  {
    newTab: false,
    title: 'Organization',
    href: '/dashboard/organization',
    iconSrc: IconOrganization,
    isEnabled: true,
  },
  {
    newTab: false,
    title: 'Ethical Oath',
    href: '/dashboard/ethical-developer-badge',
    iconSrc: IconPledge,
    isEnabled: true,
  },
  {
    newTab: false,
    title: 'Account Settings',
    href: '/dashboard/account-settings',
    iconSrc: AccountSettingsIcon,
    isEnabled: true,
  },
]

export const DASHBOARD_LINKS_HELP_WIDGET = [
  {
    newTab: true,
    title: 'Join Slack',
    href: SUPPORT.slack,
    iconSrc: IconSlack,
    isEnabled: true,
  },
  {
    newTab: false,
    title: 'Documentation',
    href: SUPPORT.docs,
    iconSrc: IconDocumentation,
    isEnabled: true,
  },
  {
    newTab: false,
    title: 'Help & Support',
    href: SUPPORT.help,
    iconSrc: IconHelp,
    isEnabled: true,
  },
  {
    newTab: true,
    title: 'Website',
    href: '/',
    iconSrc: IconWebsite,
    isEnabled: true,
  },
]

export const DATA_LIBRARY_DETAILS_IDS = {
  overview: 'overview',
  classes: 'classes',
  fairness: 'fairness',
  annotation: 'annotation',
  dataset_lineage: 'dataset_lineage',
  usage_reference: 'usage_reference',
} as const

export type DataLibraryDetailsId = KeyOf<typeof DATA_LIBRARY_DETAILS_IDS>
export const DATA_LIBRARY_DETAILS_LINKS = [
  {
    section: DATA_LIBRARY_DETAILS_IDS.overview,
    title: 'Overview',
  },
  {
    section: DATA_LIBRARY_DETAILS_IDS.classes,
    title: 'Classes',
  },
  {
    section: DATA_LIBRARY_DETAILS_IDS.fairness,
    title: 'Fairness',
  },

  {
    section: DATA_LIBRARY_DETAILS_IDS.annotation,
    title: 'Annotation',
  },
  // {
  //   section: DATA_LIBRARY_DETAILS_IDS.datasetLineage,
  //   title: 'Dataset Lineage',
  // },
  {
    section: DATA_LIBRARY_DETAILS_IDS.usage_reference,
    title: 'Usage Reference',
  },
]

export const API_PATHS = {
  personalDetails: '/api/accountSettings/personalDetails',
  changePassword: '/api/accountSettings/changePassword',
  register: '/api/auth/register',
  confirmUser: '/api/auth/confirmUser',
  resendCode: '/api/auth/resendCode',
}

export const PASSWORD_REGEX =
  /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/

export const TOOLTIP = {
  disabledDatasetSection: 'No data available!',
  disabledTableButton: 'Not available yet!',
  failedUpload: 'Check your email for more info!',
  traceability:
    'The Traceability Score represents how much is known about a dataset, and how well it is documented. It covers three areas: dataset source(s), metadata, and annotations. The score is calculated by Milestone Data Insights as a metric for transparency.',
  bookmarkSave: 'Save to collection',
  bookmarkRemove: 'Remove from collection',
  trainingMetrics: 'View training metrics',
  trainingDownload: 'Download Model',
  trainingRemove: 'Remove training',
  verifiedLabelTitle: 'What does it mean that a dataset is verified?',
  verifiedLabel:
    'A verified dataset refers to a collection meticulously curated by the MDI team, adhering to the utmost ethical and legal standards.',
}

export const LOCAL_STORAGE_SIDEBAR_STATE = 'IS_WELCOME_DIALOG_STATE_CLOSED'

export const PHASES = {
  EXPORT: 'phase-export',
  PRODUCTION_BUILD: 'phase-production-build',
  PRODUCTION_SERVER: 'phase-production-server',
  DEVELOPMENT_SERVER: 'phase-development-server',
  TEST: 'phase-test',
}
