import Image from 'next/image'
import { ArrowOutward } from '@/lib/mui'
import useSignOath from '@/hooks/mutations/useAuthUser'
import { CURRENT_USER_QUERY_KEY } from '@/hooks/queries/useCurrentUserQuery'
import { analitycs } from '@/hooks/useAnalyticsInit'
import { AnalitycsEvents } from '@/types/analyticsTypes'
import { useQueryClient } from '@tanstack/react-query'
import LoadingBtn from '@/components/LoadingButton'
import { useRouter } from 'next/navigation'
import { useSession } from 'next-auth/react'
import IconHonour from '@public/assets/illustrations/small/ethical_badge_mini.svg'
import { Skeleton, Button } from '@mui/material'

export const SecondStep = ({
  handler,
  closeHandler,
}: {
  handler: () => void
  closeHandler: () => void
}) => {
  const { mutate, isPending: loadingSign } = useSignOath()
  const queryClient = useQueryClient()
  const { data: session } = useSession()
  const router = useRouter()

  const sign = () => {
    mutate(true, {
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: [CURRENT_USER_QUERY_KEY] })
        analitycs.track(AnalitycsEvents.signEthicalOath)
        handler()
        router.refresh()
      },
    })
  }
  return (
    <>
      <div className="flex flex-col items-center justify-center bg-white px-24 pb-10 pt-24 sm:px-6">
        <h3 className="mb-[50px] text-center text-h3 font-bold">
          The MDI Ethical Oath
        </h3>
        <div className="relative w-full rounded-lg bg-green-50">
          <span className="absolute -top-[32px] left-1/2 flex h-[60px] w-[60px] -translate-x-1/2 transform items-center justify-center rounded-full bg-white">
            <Image
              src={IconHonour}
              alt="honour"
              width={48}
              height={48}
              className="flex-shrink-0 text-white"
            />
          </span>
          <p className="mb-[114px] mt-[104px] px-10 text-center text-p3">
            When using MDI, I{' '}
            <b>
              {session ? (
                <>
                  {session.user.name} {session.user.familyName}
                </>
              ) : (
                <Skeleton
                  height={20}
                  width={100}
                  variant="rounded"
                  className="mx-1 inline-block"
                />
              )}
            </b>
            , pledge to develop technology to benefit individuals, society and
            the environment.
          </p>
        </div>
        <LoadingBtn
          loading={loadingSign}
          loadingPosition="center"
          type="button"
          onClick={async (e: any) => {
            e.preventDefault()
            sign()
          }}
          color="primary"
          className="mt-5"
        >
          Sign with 1-click
        </LoadingBtn>
        <p className="mt-5 text-center text-sm text-gray-500">
          MDI Ethical Oath is not legally binding.
          <br />
          By taking this oath, you join a community dedicated to making a
          positive impact on the world.
        </p>
        <Button
          color="secondary"
          variant="text"
          endIcon={<ArrowOutward />}
          className="mt-5 font-bold"
          onClick={closeHandler}
        >
          Skip for now
        </Button>
      </div>
    </>
  )
}
