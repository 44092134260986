import type { Config } from 'tailwindcss'
export const colors = {
  blue: {
    50: '#E6F5FC',
    100: '#B8E2F5',
    200: '#99D6F0',
    300: '#66C2E9',
    400: '#40B2E3',
    500: '#1AA3DE',
    600: '#0099DA', // principal
    700: '#0086C6',
    800: '#0275B3',
    900: '#005591',
  },
  green: {
    50: '#EDF7F3',
    100: '#DBF0E7',
    200: '#C0E4D5',
    300: '#A5D9C3',
    400: '#79C6A6',
    500: '#5EBB94',
    600: '#4CB388',
    700: '#3F8D6E',
    800: '#346E58',
    900: '#2E5D4C',
  },
  orange: {
    50: '#FDF3E6',
    100: '#FBDEC0',
    200: '#F8CA9B',
    300: '#F6B578',
    400: '#F2A664',
    500: '#ED995A',
    600: '#E78F57',
    700: '#DF8351',
    800: '#D5764E',
    900: '#C96145',
  },
  neutral: {
    10: '#0000000a',
    50: '#F6F8F8',
    100: '#F1F2F2',
    200: '#E3E4E4',
    300: '#CED0D0',
    400: '#BABDBE',
    500: '#888A8B',
    600: '#58595B',
    700: '#424445',
    800: '#2D2F30',
    900: '#161A1E',
  },
  red: {
    10: '#FFE9E90a',
    50: '#FFE9E9',
    100: '#FFD3D2',
    200: '#FFBDBC',
    300: '#FFA7A5',
    400: '#FF918F',
    500: '#FF605D',
    600: '#DA3D00',
    700: '#C00400',
    800: '#930300',
    900: '#880000',
  },
  alert: {
    confirmation: '#2AAD60',
    error: '#DA3D00',
    warning: '#FFD600',
  },
  white: '#FFFFFF',
  black: '#111617',
  'neutral-700-opacity-005': '#4244450d',
  'neutral-100-opacity-005': '#4244450D',
  'blue-600-opacity-005': '#0099DA0D',
  'tranparent-grey-5': '#E8E9E9',
  // deprecated, we need to remvoe
  // text-, border-, bg-
  'primary-yellow': '#FFE700',
  'soft-yellow': '#FFF380',
  'dark-grey': '#485961',
  'light-orange': '#FFF5F0',
  'milestone-blue': '#0099DA',
  'light-blue': '#F0FAFF',
  'deep-blue': '#005275',
  'soft-blue': '#4CB8E5',
  'light-grey': '#D3DBDE',
  dust: '#F6F8F8',
  'deep-dust': '#F0F3F4',
  'light-green': '#F4FBF2',
  'soft-white': '#F2F2F2',
  grey: '#758E99',
  'red-error': '#d32f2f',
  'review-orange': '#FF955C',
  'soft-grey': '#d2f1ff',
  'dark-blue': '#0076a8',
}

export default {
  darkmode: 'false',
  corePlugins: {
    preflight: false,
  },
  content: ['./src/**/*.{js,ts,jsx,tsx,mdx}'],
  theme: {
    screens: {
      xxxl: '1535px',
      xxl: '1440px',
      xl: '1280px',
      lg: '1024px',
      md: '768px',
      sm: '640px',
      xs: '360px',
    },
    fontSize: {
      sm: '13px',
      md: '14px',
      base: '16px',
      xl: '64px',
      p1: '16px',
      p2: '14px',
      h1: '64px',
      h2: '48px',
      h3: '36px',
      h4: '28px',
      h5: '21px',
      h6: '21px',
      p3: '24px',
      micro: '10px',
    },
    lineHeight: {
      sm: '16px',
      md: '18px',
      base: '22px',
      xl: '64px',
      p1: '22px',
      p2: '22px',
      h1: '74px',
      h2: '60px',
      h3: '44px',
      h4: '38px',
      h5: '28px',
      h6: '28px',
    },
    extend: {
      backgroundImage: {
        'glamour-gradient':
          'linear-gradient(241deg, #EDF7F3 13.23%, #FFF4EA 74.3%, #FFF 141.09%)',
        'gradient-radial': 'radial-gradient(var(--tw-gradient-stops))',
        'gradient-conic':
          'conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))',
      },
      colors: colors,
      spacing: {
        15: '3.75rem',
        75: '18.75rem',
      },
      // screens: {
      //   xxl: '1360px',
      // },
      maxWidth: {
        xxs: '15rem',
      },
      zIndex: {
        max: '2147483647',
      },
    },
  },
  plugins: [],
} satisfies Config
