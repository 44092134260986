'use client'

// When using TypeScript 4.x and above
import type {} from '@mui/lab/themeAugmentation'

import { createTheme } from '@mui/material/styles'
import { palette } from '@/components/Theme/overrides/palette'
import { components } from '@/components/Theme/overrides/components'
import { breakpoints } from '@/components/Theme/overrides/breakpoints'

const finalTheme = createTheme({
  components,
  palette,
  breakpoints,
  typography: {
    fontFamily: 'inherit',
    h2: {
      // color: var(--Neutral-Neutral-900, var(--Neutral-900, #161A1E));
      // font-feature-settings: 'liga' off, 'clig' off;

      // /* Headings/H2 Bold */
      // font-family: Inter;
      fontSize: '48px',
      fontWeight: 700,
      lineHeight: '54px',
      // font-style: normal;
      // font-weight: 700;
      // line-height: 54px; /* 112.5% */
    },
  },
})

export default finalTheme
