'use client'
import { Session } from 'next-auth'
import { SessionProvider as NextSessionProvider } from 'next-auth/react'
import { useEffect } from 'react'
import { useSession } from 'next-auth/react'
import { GlobalDialogsProvider } from '@/components/GlobalDialogs'

export const SessionProvider = ({
  children,
  session,
}: {
  children: React.ReactNode
  session?: Session | null
}) => {
  return (
    <NextSessionProvider
      session={session}
      refetchInterval={0}
      refetchWhenOffline={false}
      refetchOnWindowFocus={false}
    >
      <GlobalDialogsProvider>{children}</GlobalDialogsProvider>
      <RefreshSession />
    </NextSessionProvider>
  )
}
const FIVE_MINUTES_IN_MS = 5 * 60 * 1000

export const RefreshSession = () => {
  const { data: session } = useSession()

  useEffect(() => {
    const checkSessionExpiration = () => {
      if (
        Math.abs((session?.accessTokenExpires as number) - Date.now()) <
        FIVE_MINUTES_IN_MS
      ) {
        // Sign out here
        window.location.reload()
      }
    }

    // Check every 4 minutes
    const intervalId = setInterval(checkSessionExpiration, 4 * 60 * 1000)

    // Initial check
    checkSessionExpiration()

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId)
  }, [session])

  return <></>
}
