declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true
    xxxl: true
  }
}

export const breakpoints = {
  values: {
    xs: 360,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    // '2xl': 1360,
    // xxl: 1536,
    xxl: 1440,
    xxxl: 1536,
  },
}
