'use client'
import { ArrowOutward, Button } from '@/lib/mui'
import Link from 'next/link'
import { Box } from '@/lib/mui'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormInputText } from '@/components/FormComponents/FormInputText'
import { getSession, signIn } from 'next-auth/react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useQueryParams, StringParam } from 'use-query-params'
import { FormInputPassword } from '@/components/FormComponents/FormInputPassword'
import { AuthErrorMapper, AuthErrors, handlerCaptchaResize } from '@/lib/utils'
import { analitycs } from '@/hooks/useAnalyticsInit'
import { AnalitycsEvents } from '@/types/analyticsTypes'
import config from '@/lib/config'
import { useRouter } from 'next/navigation'
import LoadingBtn from '@/components/LoadingButton'
import Recaptcha, { RecaptchaRef } from '@/components/Recaptcha/Recaptcha'

interface ISignIn {
  action: ({
    email,
    password,
    type,
  }: {
    email: string
    password: string
    type: AuthErrors
  }) => void
}

interface IFormInput {
  email: string
  password: string
  captchaToken?: string
}

const schema = yup.object().shape({
  email: yup.string().required().label('Email'),
  password: yup.string().required().label('Password'),
  captchaToken: config.NEXT_PUBLIC_CLOUDFLARE_SITE_KEY
    ? yup.string().required().label('Captcha')
    : yup.string(),
})

const defaultValues = {
  email: '',
  password: '',
  captchaToken: '',
}

const SignInForm = ({ action }: ISignIn) => {
  const ref = useRef<RecaptchaRef>()
  const router = useRouter()
  const [queryParams, setQueryParams] = useQueryParams({
    error: StringParam,
    callbackUrl: StringParam,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [genericError, setGenericError] = useState<null | string>(null)
  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema),
  })
  const handleSubmitCallback = async (formData: IFormInput) => {
    try {
      setIsLoading(true)
      const signInResult = await signIn('credentials', {
        email: formData.email,
        password: formData.password,
        captchaToken: formData.captchaToken,
        redirect: false,
      })
      if (signInResult?.error) {
        setIsLoading(false)
        const errorType = AuthErrorMapper(signInResult?.error)
        if (errorType === AuthErrors.incorrectCredentials) {
          setError('email', {
            type: 'manual',
          })
          setError('password', {
            type: 'manual',
          })
        }
        if (errorType === AuthErrors.changePasswordRequired) {
          action({
            email: formData.email,
            password: formData.password,
            type: AuthErrors.changePasswordRequired,
          })
        }
        if (errorType === AuthErrors.userIsNotConfirmed) {
          action({
            email: formData.email,
            password: formData.password,
            type: AuthErrors.userIsNotConfirmed,
          })
        }
        if (errorType === AuthErrors.preAuthentication) {
          setGenericError(AuthErrors.incorrectCredentials)
        } else {
          setGenericError(errorType ? errorType : '')
        }
      } else {
        const session = await getSession()
        // let firstSignIn = sessionStorage.getItem('firstSignIn')
        analitycs.track(AnalitycsEvents.signIn)
        if (session && session.user) analitycs.setUserId(session?.user.id)
        if (queryParams.callbackUrl && queryParams.callbackUrl !== '/') {
          router.replace(queryParams.callbackUrl)
        } else {
          router.replace('/')
        }
      }
    } catch (error) {
      setIsLoading(false)
      setGenericError('Something did not work as expected. Please try again!')
    }
    ref.current?.reset()
  }

  useEffect(() => {
    if (errors.captchaToken && errors.captchaToken.message)
      setGenericError(errors.captchaToken.message)
  }, [errors])

  useEffect(() => {
    window.addEventListener('message', handlerCaptchaResize)
    return () => window.removeEventListener('message', handlerCaptchaResize)
  }, [])

  return (
    <div className="flex h-full flex-col gap-10">
      <h3 className="text-center text-h3 font-bold md:text-start">Sign in</h3>
      <div className="grid gap-2.5">
        <div className="grid gap-10 rounded-md bg-white">
          <Box
            className="grid max-w-full gap-6 md:max-w-md"
            component="form"
            onSubmit={handleSubmit(handleSubmitCallback)}
            noValidate
          >
            {genericError ? (
              <p className="text-sm font-medium text-red-error">
                {genericError}
              </p>
            ) : (
              ''
            )}
            <FormInputText
              name="email"
              control={control}
              label="Email"
              data-cy="sign-in-email"
              color="secondary"
              placeholder="Enter email..."
              autocomplete="username"
            />
            <FormInputPassword
              name="password"
              control={control}
              label="Password"
              data-cy="sign-in-password"
              color="secondary"
              placeholder="Enter password..."
              autocomplete="current-password"
            />
            <Recaptcha
              reference={ref}
              onSuccess={(token) => setValue('captchaToken', token)}
              onExpire={() => ref.current?.reset()}
            />
            <div className="flex flex-col gap-2.5">
              <LoadingBtn
                className="w-full justify-self-start"
                color="primary"
                size="large"
                data-cy="get-access-info-button"
                loading={isLoading}
                icon={<ArrowOutward />}
              >
                Sign In
              </LoadingBtn>
              <Button
                color="primary"
                variant="text"
                endIcon={<ArrowOutward />}
                href="/auth/forgot-password"
              >
                Forgot password
              </Button>
            </div>
          </Box>
        </div>
      </div>
      <div className="mt-auto grid grid-cols-1">
        <div className="flex w-full justify-center">
          <p>
            Don&apos;t have an account?{' '}
            <Link className="anchor" href="/auth/register" replace prefetch>
              Register here
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default SignInForm
