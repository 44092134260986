'use client'
import Image from 'next/image'
import SignInForm from '@/components/Auth/SignInForm'
import BackgroundImage from '@public/assets/sign_in_photo.jpg'
import Logo from '@public/assets/logo-blue.svg'
import ChangePasswordForm from '@/components/Auth/ChangePasswordForm'
import { Suspense, useState } from 'react'
import { QueryParamProvider } from '@/app/query-param-provider'
import { AuthErrors } from '@/lib/utils'
import LoginConfirmForm from '@/components/Auth/Register/LoginConfirmForm'
import BackButton from '@/components/BackButton'

const SignIn = () => {
  const [isRequiredToChangePassword, setIsRequiredToChangePassword] =
    useState(false)
  const [isRequiredToConfirm, setIsRequiredToConfirm] = useState(false)
  const [tempCredentials, setTempCredentials] = useState<{
    email: string | null
    password: string | null
  }>({
    email: null,
    password: null,
  })
  const loginStateChange = ({
    email,
    password,
    type,
  }: {
    email: string
    password: string
    type: AuthErrors
  }) => {
    type === AuthErrors.changePasswordRequired &&
      setIsRequiredToChangePassword(true)
    type === AuthErrors.userIsNotConfirmed && setIsRequiredToConfirm(true)
    setTempCredentials({ email, password })
  }

  return (
    <div className="flex h-full flex-row">
      <div className="main-container flex max-w-full flex-col items-center py-[40px] md:max-w-[680px]">
        <div className="flex h-full w-full max-w-[460px] flex-col gap-16">
          <div className="flex flex-col items-start gap-10">
            <Image quality={80} src={Logo} alt="" />
            <BackButton />
          </div>
          {!isRequiredToChangePassword && !isRequiredToConfirm && (
            <QueryParamProvider>
              <Suspense>
                <SignInForm action={loginStateChange}></SignInForm>
              </Suspense>
            </QueryParamProvider>
          )}
          {isRequiredToChangePassword && (
            <Suspense>
              <ChangePasswordForm data={tempCredentials}></ChangePasswordForm>
            </Suspense>
          )}
          {isRequiredToConfirm && tempCredentials.email && (
            <Suspense>
              <LoginConfirmForm data={tempCredentials}></LoginConfirmForm>
            </Suspense>
          )}
        </div>
      </div>
      <div className="relative hidden w-full md:flex">
        <Image
          placeholder="blur"
          quality={80}
          className="absolute right-0 top-0 h-full w-full object-cover"
          src={BackgroundImage}
          alt=""
        />
      </div>
    </div>
  )
}

export default SignIn
