'use client'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { FeatureToggle } from '@/components/FeatureFlags/FeatureToggleProvider'
import { featureFlags } from '@/components/FeatureFlags/utils'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import config from '@/lib/config'
import useAnalyticsInit from '@/hooks/useAnalyticsInit'
import { useState } from 'react'
import { AppProvider } from '@/components/Providers/AppProvider'
import { GlobalDialogsProvider } from '@/components/GlobalDialogs'

type Props = {
  env: {
    HOTJAR_ID: number
    HOTJAR_VERSION: number
  }
  children?: React.ReactNode
}

function useReactQueryClient() {
  const [queryClient] = useState(function () {
    return new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 60 * 1000,
          refetchOnMount: true,
        },
      },
    })
  })

  return queryClient
}

export const Providers = ({ children, env }: Props) => {
  useAnalyticsInit({
    hotjarId: env.HOTJAR_ID,
    hotjarVersion: env.HOTJAR_VERSION,
    amplitudeId: config.NEXT_PUBLIC_AMPLITUDE_API_KEY,
    isDev: config.isDev(),
  })
  return (
    <FeatureToggle enabledFeatures={featureFlags}>
      <AppProvider>
        <QueryClientProvider client={useReactQueryClient()}>
          {/* <GlobalDialogsProvider>{children}</GlobalDialogsProvider> */}
          {children}
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AppProvider>
    </FeatureToggle>
  )
}
