'use client'
import { PageEthicalOathBadge } from '@/tina/types'
import { tinaField } from 'tinacms/dist/react'
import Link from 'next/link'
import { ArrowOutward, Button, Skeleton } from '@/lib/mui'
import { CMSLink, formatNumbers } from '@/lib/utils'
import styles from './badge.module.css'
import Image from 'next/image'
import badgeIcon from '@public/assets/illustrations/medium/ethical_badge.svg'
import parse from 'html-react-parser'
import { useSession } from 'next-auth/react'
import { MarkdownLocal } from '@/lib/content/utils'
import useOathStatsQuery from '@/hooks/queries/useOathStatsQuery'
import IconHonour from '@public/assets/illustrations/small/ethical_badge_mini.svg'
import { internalNav } from '@/components/Header/constants'

export const AvatarBadge = () => {
  const { data: session } = useSession()

  return (
    <div className={styles.badge}>
      <div className="relative flex">
        <p className="absolute -right-[50px] -rotate-90 self-center text-sm text-grey">
          Milestone Data Insights
        </p>
        <Image src={badgeIcon} alt="badge" width={150} height={150} />
      </div>
      <div className="flex flex-col gap-1.5">
        <span className="text-left text-[20px] font-bold">
          {session ? (
            <>
              {session.user.name} {session.user.familyName}
            </>
          ) : (
            <Skeleton height={30} width={100} variant="rounded" />
          )}
        </span>
        <span className="text-sm text-neutral-500">
          Signed MDI Ethical Oath
        </span>
      </div>
    </div>
  )
}

export function EthicalOathSignBadge(props: {
  pageData: PageEthicalOathBadge
  disableRedirect?: boolean
}) {
  const pageData = props.pageData
  const { data: oathCounter, isLoading: isLoadingOathCounter } =
    useOathStatsQuery({})
  const { data: session } = useSession()

  return (
    <div className="mb-10 flex flex-col gap-10">
      <div className="relative flex h-auto w-full flex-col items-start gap-2.5 rounded-lg bg-white p-6 pt-8">
        <div className="absolute -top-[32px] left-10 flex h-[60px] w-[60px] items-center justify-center rounded-full bg-neutral-100">
          <Image
            src={IconHonour}
            alt="honour"
            width={48}
            height={48}
            className="flex-shrink-0 text-white"
          />
        </div>
        <div className="text-p3 leading-h5">
          When using MDI, I{' '}
          {session ? (
            <span className="font-bold">
              {session.user.name} {session.user.familyName}
            </span>
          ) : (
            <Skeleton
              height={20}
              width={100}
              variant="rounded"
              className="mx-1 inline-block"
            />
          )}
          , pledge to develop technology to benefit individuals, society and the
          environment.
        </div>
        <div className="flex w-full flex-col items-center justify-between sm:flex-row">
          <span
            className="text-sm"
            data-tina-field={tinaField(pageData, 'subtitle')}
          >
            <MarkdownLocal
              content={pageData.subtitle}
              replaceData={{
                counter: oathCounter?.users_accepted
                  ? formatNumbers(oathCounter?.users_accepted)
                  : 0,
              }}
            />
          </span>
          <Link className="sm:ml-auto" href={internalNav.ethics}>
            <Button variant="text" endIcon={<ArrowOutward />}>
              Our Ethical Principles
            </Button>
          </Link>
        </div>
      </div>
      <div className="flex flex-col gap-6 rounded-xl bg-tranparent-grey-5 p-15 md:flex-row md:items-center md:gap-12">
        <AvatarBadge />

        <div className="flex flex-col items-start gap-5">
          <div className="flex flex-col gap-2.5">
            <h1
              className="text-h3 font-bold leading-h3"
              data-tina-field={tinaField(pageData, 'title1')}
            >
              <MarkdownLocal content={pageData.title1} />
            </h1>
            <div className="" data-tina-field={tinaField(pageData, 'title2')}>
              <MarkdownLocal content={pageData.title2} />
            </div>
          </div>
          <Link {...CMSLink(pageData.button)}>
            <Button
              color="primary"
              data-tina-field={tinaField(pageData.button)}
            >
              {parse(pageData.button.text)}
            </Button>
          </Link>
          <div className="flex flex-col items-start gap-2.5">
            <span className="font-bold">What your badge represents:</span>
            <div className="flex flex-col">
              {pageData.badgeBenefits.map((benefit, index) => (
                <span key={`badge-benefit-${index}`} className="text-md">
                  {`⭐️ ${benefit}`}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
